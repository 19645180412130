#botonesEleccionComercial {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
}

#contentTipoEnvio,
#contentCrearTipos,
#contentEliminarTipos {
    display: none;
}

#contentTipoEnvio {
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 550px;
    background-color: var(--color-greenback);
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    display: none;
    flex-direction: column;
    padding: 25px 35px;
}

