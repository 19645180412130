*,
*:before,
*:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.logoLogin {
    width: 150px;
}

.background {
    width: 430px;
    height: 520px;
    position: absolute;
    transform: translate(20%, -50%);
    left: 50%;
    top: 50%;
}

.shape {
    height: 100px;
    width: 100px;
    position: absolute;
    border-radius: 50%;
    transform: translate(-470%, -450%);
  }
  
  
  .shape:last-child {
    right: -30px;
    bottom: -80px;
  }
  

.mainImagen {
    width: 430px;
    height: 420px;
    transform: translate(30%, 15%);
}

.sonImagen {
    width: 450px;
}

.mainLogin {
    top: 50%;
    left: 50%;
    width: 80%;
    height: 600px;
    background-color: var(--color-greenback);
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    border: 2px solid var(--color-borders);
}

form {
    height: 520px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
}

form * {
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

form h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
}

label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}

input {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}

::placeholder {
    color: #e5e5e5;
}

button {
    margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.social {
    margin-top: 30px;
    display: flex;
}

.social div {
    background: red;
    width: 150px;
    border-radius: 3px;
    padding: 5px 10px 10px 5px;
    background-color: rgba(255, 255, 255, 0.27);
    color: #eaf0fb;
    text-align: center;
}

.social div:hover {
    background-color: rgba(255, 255, 255, 0.47);
}

.social .fb {
    margin-left: 25px;
}

.social i {
    margin-right: 4px;
}

/*------------------------ alerta de bienvenida ---------------------------*/

.Content_Swall_ {
    margin-top: 5px;
    border: solid 2px rgb(106, 250, 137) !important;
    border-radius: 2rem ;
    width: 400px  !important;
    height: 4rem;
    display: flex !important;
    justify-content: space-evenly !important;
    background: white;
    align-items: center;
    box-shadow: none !important;
    z-index: 99 !important;
}


.password-input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .password-input-container {
    position: relative;
    display: flex;
    width: 100%;
  }
  
  /* /* input {
    width: 100%;
    padding-right: 40px; 
  }
   */
  .password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 56%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
