.contentCardsDashboard {
    display: flex;
    /* word-wrap: break-word; */
    flex-wrap: wrap;
    justify-content: center;
}

.contentCardOptios {
    display: flex;
    padding: 26px;
    font-size: 20px;
    text-decoration: none;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    color: black;
    width: 29%;
    height: 200px;
    background-color: white;
    margin: 20px;
    border: 2px solid var(--color-green);
    border-radius: 10px;
    transition: all 100ms ease-out;
}

.contentCardOptios:hover {
    color: white;
    cursor: pointer;
    height: 202px;
    transition: all 100ms ease-in;
    background-color: var(--color-green);
    border: 2px solid var(--color-green);
}

#contentCardOptios_color {
    display: flex;
    padding: 26px;
    font-size: 20px;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    color: black;
    width: 29%;
    height: 200px;
    background-color: white;
    margin: 20px;
    border: 2px solid gray;
    border-radius: 10px;
}

.contentCardOptios,
#contentCardOptios_color>h3 {
    color: var(--color-green);
}

.btnDashboard {
    margin-top: 10px;
    background-color: var(--color-green);
    border: 2px solid var(--color-borders);
    transition: all 100ms ease-out;
}

.btnDashboard:hover {
    margin-top: 0;
    background-color: var(--color-greenback);
    color: white;
    transition: all 300ms ease-in;
    margin-top: 10px;
}

/*----------------------------------- textos ------------------------------------------*/

.conteoText {
    font-size: 50px;
}

.conteoTextError {
    font-size: 50px;
    position: absolute;
    color: white;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-45%, 0%);
}

/*----------------------------------- iconos ------------------------------------------*/

.icon-Daskboard {
    font-size: 50px;
}

.icon-Daskboard2 {
    font-size: 60px;
}


/* @media (max-width: 1920px) {
    .contentCardOptios {
        padding: 20px;
    }
} */

@media (max-width: 1366px) {
    .contentCardOptios {
        padding: 20px;
    }

    .contentCardOptios {
        width: 28%;
        height: 150px;
        font-size: 15px;
    }

    .contentCardOptios:hover {
        width: 28%;
        height: 150px;
    }

    .conteoText {
        font-size: 30px;
    }
}