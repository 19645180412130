.pie-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(0deg, #1c262a, transparent 100%)!important; 
}

.pie {
    text-align: center;
    padding: 10px; 
    color: white;
}

.pie>p>a {
    text-decoration: none;
    color: var(--color-font);
}