.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background: 
      linear-gradient(90deg, #1c262a, transparent 120%), /* Gradiente de izquierda a derecha */
      linear-gradient(180deg, #1c262a, transparent 10%); /* Gradiente de arriba hacia abajo y fondo sólido */
    backdrop-filter: blur(3px);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: var(--color-button);
    display: block;
    margin-top: 30px;
    transition: 0.3s;
}

.sidenav a:hover {
    color: var(--color-button)
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 56px;
    margin-left: 50px;
    cursor: pointer;
    color: var(--color-button);
}

.textMenu {
    font-size: 30px;
    cursor: pointer;
    color: var(--color-button);
}

#main {
    transition: margin-left .5s;
    padding: 16px;
    background: linear-gradient(180deg, #1c262a, transparent 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-button);
}

/* #main::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
} */

.logoNav {
    width: 50px;
    border-radius: 27%;
    margin-right: 13px;
    z-index: 1;
}

.menu {
    display: flex;
    align-items: center;
}

.usuario {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    margin-right: 30px;
}

.cerrar {
    display: flex;
    width: 200px;
    justify-content: space-around;
    cursor: pointer;
}

.nameUser {
    display: flex;
    width: 210px;
    justify-content: space-around;
}

.version {
    color: var(--color-green);
    position: absolute;
    transform: translate(100%, 2300%);
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

@media (max-width: 1366px) {
    #main {
        padding: 8px;
    }
}