.mainContenDaskboard {
    height: 590px;
    width: 90%;
    background-color: var(--color-greenback);
    position: absolute;
    transform: translate(-50%, -45%);
    top: 49%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 25px 35px;
    color: var(--color-button);
    overflow: auto;
}



.mainContenDaskboard::-webkit-scrollbar {
    -webkit-appearance: none;
}

.mainContenDaskboard::-webkit-scrollbar:vertical {
    width: 10px;
}

.mainContenDaskboard::-webkit-scrollbar-button:increment,
.mainContenDaskboard::-webkit-scrollbar-button {
    display: none;
}

.mainContenDaskboard::-webkit-scrollbar:horizontal {
    height: 10px;
}

.mainContenDaskboard::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.mainContenDaskboard::-webkit-scrollbar-track {
    border-radius: 10px;
}
#ContentReporteNotification, #ContentEnvios {
    display: none;
}


@media (max-width: 1366px) {
    .mainContenDaskboard {
        height: 440px;
        width: 98%;
    }
} 

@media (max-width: 1220px) {
    .mainContenDaskboard {
        height: 480px;
        width: 98%;
    }

    .contentCardOptios {
        padding: 17px;
    }
}

