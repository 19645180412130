.iconCardTamplate,
.iconCardTamplate1 {
    font-size: 30px;
    color: var(--color-null);
    margin-top: 7px;
    margin-bottom: 7px;
    cursor: pointer;
}

.iconCardTamplate3 {
    font-size: 44px;
    cursor: pointer;
    color: var(--color-red);
}

.iconCardTamplate4,
.iconCardTamplate5 {
    font-size: 44px;
    color: var(--color-verification);
    cursor: pointer;
}

.iconCardTamplate2_1,
.iconCardTamplate6 {
    color: var(--color-yellow);
    font-size: 44px;
    cursor: pointer;
}

.iconCardTamplate5_1,
.iconCardTamplate6_1 {
    color: var(--color-verification);
    font-size: 44px;
    cursor: pointer;
}

.mainCard {
    height: 400px;
    width: 76%;
    padding-bottom: 25px;
    overflow: auto;
    margin: auto;
    position: absolute;
    transform: translate(-36%, -36%);
    top: 50%;
    left: 50%;
}

.mainCard::-webkit-scrollbar {
    -webkit-appearance: none;
}

.mainCard::-webkit-scrollbar:vertical {
    width: 10px;
}

.mainCard::-webkit-scrollbar-button:increment,
.mainCard::-webkit-scrollbar-button {
    display: none;
}

.mainCard::-webkit-scrollbar:horizontal {
    height: 10px;
}

.mainCard::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.mainCard::-webkit-scrollbar-track {
    border-radius: 10px;
}

.sonCard {
    width: 95%;
    height: 70px;
    background-color: rgb(255 255 255);
    color: black;
    margin: 20px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
}

.iconCard {
    font-size: 100px;
    margin: 20px;
}

.nullCard {
    position: absolute;
    transform: translate(85%, 60%);
    text-align: center;
}

.sonCard_ {
    width: 95%;
    height: 70px;
    background-color: rgb(30 199 136 / 38%);
    margin: 20px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    color: gray;
    display: flex;
    padding: 17px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-greenback);
}

.mainCard>.sonCard>.datosContentName {
    width: 330px;
}

.mainCard>.sonCard>.datosContentViews {
    width: 440px;
}

.mainCard>.sonCard_>.datosContentName {
    width: 330px;
}

.mainCard>.sonCard_>.datosContentViews {
    width: 550px;
}

.mainCalendario_>.contentText {
    display: flex;
    justify-content: center;
}

/*----------------------------- botones de estado ----------------------------*/

.Contentbtn {
    width: 270px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.Contentbtn_ {
    width: 150px;
    display: flex;
    justify-content: space-around;
}

.Content_Swal_All_alert {
    overflow: hidden;
    z-index: 9;
    backdrop-filter: blur(5px);
}

.Content_Swall_2 {
    z-index: 99 !important;
}

#allCalendario_ {
    display: none;
    flex-direction: column;
    width: 80%;
    height: 600px;
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
}

#mainCalendario_ {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    position: absolute;
    padding: 30px;
    width: 60%;
    background-color: rgb(36 49 54);
    border: 2px solid rgb(46 60 59);
    color: var(--color-green);
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mainCalendario_2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    height: 150px;
    color: #080710;
    width: 30%;
    border: 2px solid rgb(46 60 59);
    background-color: rgb(50, 67, 73);
}

.content-calendario {
    background-color: #ffffff;
    width: 100%;
    border-radius: 10px;
    border: solid 2px gray;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 10px;
}

.contenct-btn-calendaraio {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
}


/*---------------------------- ayudas de los botones visuales ----------------------------*/
.hoverIcon {
    position: absolute;
    width: 123px;
    transform: translate(-30%, 0%);
    transition: all ease-in 300ms;
    border-radius: 10px;
    padding: 10px;
    background-color: var(--color-green);
    display: none;
    font-size: 15px;
    justify-content: center;
    color: #f1f2f3;
    z-index: 999;
}

.iconCardTamplate3:hover + .hoverIcon,
.iconCardTamplate2_1:hover + .hoverIcon,
.iconCardTamplate6:hover + .hoverIcon,
.iconCardTamplate6_1:hover + .hoverIcon,
.iconCardTamplate1:hover + .hoverIcon,
.iconCardTamplate5_1:hover + .hoverIcon {
    display: flex;
    opacity: 1; /* Hace el elemento completamente visible */
    visibility: visible; /* Hace el elemento aparecer */
}

.textHoverIcon {
    margin: 0;
}

/*---------------------------- prueba de estilos ----------------------------*/

.contentTextDatos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    text-align: center;
    color: #f1f2f3;
}

#btnPrueba {
    color: var(--color-verification);
    width: 50%;
    background-color: white;
    text-align: center;
    cursor: pointer;
    padding: 30px;
    border-radius: 10px;
    position: absolute;
    transform: translate(50%, -50%);
    z-index: 99;
    margin-top: 5px;
    display: none;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}


#btnPrueba>input {
    border-radius: 10px;
    border: 2px solid gray;
    width: 70%;
}

.btn {
    width: 10px;
    font-size: 20px;
    color: var(--color-button);
    cursor: pointer;
    background-color: var(--color-green);
}

.btn:hover {
    transition: all 100ms ease-in;
    background-color: var(--color-borders);
    color: white;
    cursor: pointer;
}

/* #btnPruebaclick {
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 25px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    transform: translate(-150%, -5%);
} */

#allData {
    display: none;
    width: 40%;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-greenback);
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--color-green);
}

.btnBd {
    padding: 20px;
    text-align: center;
    margin: 10px;
    cursor: pointer;
    transition: all 300ms ease-out;

    background-color: var(--color-font);
    border-radius: 10px;
    border: 2px solid gray;
    color: #080710;
}

.btnBd:hover {
    transition: all 100ms ease-in;
    background-color: var(--color-borders);
    color: white;
}

.contentAllbtn {
    margin-top: 20px;
}


@media (max-width: 1920px) {
    .mainCard>.sonCard>.datosContentName {
        width: 370px;
    }

    .mainCard>.sonCard_>.datosContentName {
        width: 370px;
    }

    .sonCard,
    .sonCard_ {
        width: 92%;
        height: 47px;
        margin: 10px 0px 10px 60px;
        font-size: 13px;
    }

    .mainCard {
        height: 400px;
        width: 82%;
        transform: translate(-40%, -36%);
    }

    .iconCardTamplate3,
    .iconCardTamplate5_1,
    .iconCardTamplate6_1,
    .iconCardTamplate2_1,
    .iconCardTamplate6 {
        font-size: 37px;
    }

    .iconCardTamplate1 {
        font-size: 25px;
    }

    #allData {
        z-index: 99;
    }

}

@media (max-width: 1366px) {
    .mainCard>.sonCard>.datosContentName {
        width: 380px;
    }

    .mainCard>.sonCard_>.datosContentName {
        width: 380px;
    }

    .sonCard,
    .sonCard_ {
        width: 94%;
        height: 50px;
        margin: 10px 0px 10px 20px;
        font-size: 13px;
    }

    #mainAgregar {
         width: 80%;
    }

    .iconCardTamplate3,
    .iconCardTamplate5_1,
    .iconCardTamplate6_1,
    .iconCardTamplate2_1,
    .iconCardTamplate6 {
        font-size: 37px;
    }

    .iconCardTamplate1 {
        font-size: 25px;
    }

    .mainCard {
        height: 366px;
        width: 82%;
        transform: translate(-40%, -36%);
    }

    #allData {
        z-index: 99;
    }

}