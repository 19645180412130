#Tabla_Datos_2 {
    width: 100%;
    height: 500px;
    background-color: var(--color-green);
    border-radius: 10px;
    text-align: center;
    overflow: auto;
    display: none;
    justify-content: center;
}

#Tabla_Datos_2 {
    padding: 20px;
    color: #f1f2f3;
}

#Tabla_Datos_2::-webkit-scrollbar {
    -webkit-appearance: none;
}

#Tabla_Datos_2::-webkit-scrollbar:vertical {
    width: 10px;
}

#Tabla_Datos_2::-webkit-scrollbar-button:increment,
#Tabla_Datos_2::-webkit-scrollbar-button {
    display: none;
}

#Tabla_Datos_2::-webkit-scrollbar:horizontal {
    height: 10px;
}

#Tabla_Datos_2::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

#Tabla_Datos_2::-webkit-scrollbar-track {
    border-radius: 10px;
}


#Tabla_Datos_3 {
    width: 100%;
    height: 500px;
    background-color: var(--color-green);
    border-radius: 10px;
    text-align: center;
    overflow: auto;
    display: none;
    justify-content: center;
}

#Tabla_Datos_3 {
    padding: 20px;
    color: #f1f2f3;
}

#Tabla_Datos_3::-webkit-scrollbar {
    -webkit-appearance: none;
}

#Tabla_Datos_3::-webkit-scrollbar:vertical {
    width: 10px;
}

#Tabla_Datos_3::-webkit-scrollbar-button:increment,
#Tabla_Datos_3::-webkit-scrollbar-button {
    display: none;
}

#Tabla_Datos_3::-webkit-scrollbar:horizontal {
    height: 10px;
}

#Tabla_Datos_3::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

#Tabla_Datos_3::-webkit-scrollbar-track {
    border-radius: 10px;
}