.mainContenInicio {
    height: 540px;
    width: 90%;
    background-color: var(--color-greenback);
    position: absolute;
    transform: translate(-50%, -45%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 25px 35px;
    color: var(--color-button);
}

.agregarContent {
    width: 170px;
    height: 170px;
    background-color: var(--color-borders);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-340%, -50%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-out;
    font-size: 50px;
    cursor: pointer;
}

.agregarContent:hover {
    transition: all 100ms ease-in;
    position: absolute;
    background-color: #00a878;
}


/*------------------ agregar un nuevo mensage -------------------*/

#mainAgregar {
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 600px;
    background-color: var(--color-greenback);
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    display: none;
    flex-direction: column;
    padding: 25px 35px;
}

/* 
#sonAgregar {
    /* margin: 20px; 
} */


.contentTitle {
    color: white;
    /* margin: 10px; */
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.btnCloseVista {
    cursor: pointer;
}

.iconAgregar {
    font-size: 70px;
}

.agregarContent_ {
    width: 210px;
    height: 210px;
    background-color: var(--color-borders);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-out;
    font-size: 50px;
    cursor: pointer;
}

.agregarContent_:hover {
    transition: all 100ms ease-in;
    position: absolute;
    background-color: var(--color-green);
}

/*------------------------------ filtros ------------------------------------- */

.contentFilters {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-around;
    width: 60%;
    padding: 10px;
}

.search {
    width: 50%;
    margin-top: 0;
    border-radius: 10px;
    border: 2px solid gray;
    text-align: center;
    background-color: white;
    color: var(--color-green);
}

.search::placeholder {
    color: #00a878a7;
}


.funtionSelect {
    background-color: white;
    width: 300px;
    height: 50px;
    border-radius: 10px;
    font-size: 15px;
    text-align: center;
    color: var(--color-green);
}

@media (max-width: 1920px) {
    .agregarContent {
        transform: translate(-350%, -50%);
    }
}

@media (max-width: 1366px) {
    .agregarContent_ {
        width: 180px;
        height: 180px;
    }

    .agregarContent {
        transform: translate(-363%, -50%);
    }

    .mainContenInicio {
        height: 480px;
        width: 98%;
    }
}

@media (max-width: 1245px) {
    .agregarContent {
        transform: translate(-330%, -50%);
    }

    .mainContenInicio {
        height: 490px;
        width: 95%;
    }
}

@media (max-width: 1100px) {
    .mainContenInicio {
        height: 490px;
        width: 98%;
    }
}