#mainDatosPrincipales {
    display: flex;
    justify-content: center;
    position: absolute;
    transform: translate(0%, 10%);
}

.sonDatosPrincipales {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentDatosPrincipales {
    width: 500px;
    height: 400px;
    margin: 20px;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(33 26 26 / 68%);
    border-radius: 10px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.labelDatosPrincipales {
    margin: 20px;
    font-size: 23px;
}

.selectDatosPrincipales {
    width: 340px;
    height: 55px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.344);
    color: var(--color-green);
    font-size: 15px;
    text-align: center;
}

.inputDatosPrincipales {
    width: 340px;
    height: 55px;
    border-radius: 10px;
    text-align: center;
    color: var(--color-green);
    background-color: rgba(255, 255, 255, 0.344);
    border: 2px solid gray;
}

.inputDatosPrincipales::placeholder {
    color: #00a878af;
}

.btndatos {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn1 {
    width: 200px;
    height: 50px;
    background-color: var(--color-font);
    border-radius: 10px;
    border: 2px solid gray;
    transition: all 300ms ease-out;
    margin-top: 10px;
}

.btn1:hover {
    transition: all 100ms ease-out;
    background-color: var(--color-borders);
    color: white;
}


#mainCalendario {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    position: absolute;
    padding: 30px;
    width: 53%;
    height: 430px;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(255, 255, 255);
    color: var(--color-green);
    border-radius: 10px;
    transform: translate(73%, 10%);
}

#allCalendario {
    display: none;
}

.checkboxCalendario {
    border-radius: 30px;
}

.iconDatosPrincipales {
    font-size: 200px;
    transform: translate(70%, -20%);
    color: white;
}

.icon2DatosPrincipales{
    font-size: 200px;
    transform: translate(30%, 70%);
    color: white;
    position: absolute;
}

.selectCalendario {
    width: 100%;
    margin: 20px;   
    border-radius: 20px;
    height: 54px;
}

.Iphone-content {
    position: absolute;
    transform: translate(280%, 13%);
}