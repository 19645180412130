.mainContenAyuda {
    height: 590px;
    width: 90%;
    background-color: white;
    color: black;
    position: absolute;
    transform: translate(-50%, -45%);
    top: 49%;
    left: 50%;
    overflow: auto;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 2px solid var(--color-verification);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 25px 35px;
    color: var(--color-button);
}

.mainContenAyuda::-webkit-scrollbar {
    -webkit-appearance: none;
}

.mainContenAyuda::-webkit-scrollbar:vertical {
    width: 20px;
}

.mainContenAyuda::-webkit-scrollbar-button:increment,
.mainContenAyuda::-webkit-scrollbar-button {
    display: none;
}

.mainContenAyuda::-webkit-scrollbar:horizontal {
    height: 10px;
}

.mainContenAyuda::-webkit-scrollbar-thumb {
    background-color: var(--color-verification);
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.mainContenAyuda::-webkit-scrollbar-track {
    border-radius: 10px;
}

.iconCardTamplatePause {
    font-size: 50px;
}

.iconCardTamplatePlay {
    font-size: 35px;
}

.IconoDuda {
    color: var(--color-green);
    position: absolute;
    font-size: 250px;
    transform: translate(395%, -150%);
    display: fixed;
}

/*------------------------------------- textos -----------------------------------------*/
.titleMainInicioAyuda {
    color: black;
}

.attributes {
    color: black;
    display: flex;
    width: 70%;
    margin: 20px;
    flex-wrap: wrap;
    padding: 35px;

}

.attribute {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 15px;
}

.attribute>p {
    font-size: 23px;
    padding-left: 20px;
}

@media (max-width: 1366px) {
    .mainContenAyuda {
        height: 493px;
        width: 97%;
    }
    .IconoDuda { 
        display: none;
    }
}

@media (max-width: 1220px) {
    .mainContenAyuda {
        height: 473px;
        width: 97%;
    }
    .IconoDuda { 
        display: none;
    }
}