.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

/* .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #767676;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
} */

.logo {
    width: 425px;
    border-radius: 10%;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
