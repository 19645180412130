.iconConfiguracion {
    font-size: 50px;
}


.contentCardConfiguracionOptions {
    display: flex;
    padding: 26px;
    font-size: 20px;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    color: var(--color-font);
    width: 30%;
    height: 200px;
    background-color: white;
    margin: 20px;
    border: 2px solid var(--color-font);
    border-radius: 10px;
    transition: all 100ms ease-out;
    text-align: center;
}

.contentCardConfiguracionOptions:hover {
    transition: all 100ms ease-in;
    background-color: var(--color-green);
    color: var(--color-button);
    font-size: 23px;
    cursor: pointer;
    text-align: center;
}


.agregarContentC {
    width: 170px;
    height: 170px;
    background-color: var(--color-borders);
    transform: translate(-100%, 20%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-out;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.agregarContentC:hover {
    transition: all 100ms ease-in;
    background-color: var(--color-green);
}

.agregarContentC2 {
    width: 170px;
    height: 170px;
    background-color: var(--color-borders);
    transform: translate(-130%, 70%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-out;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.agregarContentC2:hover {
    transition: all 100ms ease-in;
    background-color: var(--color-green);
}

.iconConfiguracionVerificatiion {
    font-size: 30px;
}

.formConfiguracion {
    background-color: var(--color-green);
    height: 350px;
    width: 90%;
    overflow: auto;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 1px 50px 35px;
}

.backForms {
    transform: translate(0%, 0%);
}

.backForms_ {
    transform: translate(0%, 0%);
}

.backForms_1 {
    transform: translate(0%, 630%);
}

.formConfiguracion::-webkit-scrollbar {
    -webkit-appearance: none;
}

.formConfiguracion::-webkit-scrollbar:vertical {
    width: 10px;
}

.formConfiguracion::-webkit-scrollbar-button:increment,
.formConfiguracion::-webkit-scrollbar-button {
    display: none;
}

.formConfiguracion::-webkit-scrollbar:horizontal {
    height: 10px;
}

.formConfiguracion::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.formConfiguracion::-webkit-scrollbar-track {
    border-radius: 10px;
}

/*------------------------------------ text -------------------------------*/

.listasDias,
.listashoras {
    color: white;
    font-size: 25px;
    display: flex;
    /* align-items: center; */
}

.textConfiguracion {
    margin: 20px;
}

.horastext>ul>li {
    margin-left: 50px;
    font-size: 20px;
}

/*------------------------------------ contenido -------------------------------*/

.contentDiasGet {
    width: 70%;
    margin-top: 50px;
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
}

#contentDias,
#contentHoras,
#contentUser,
#contentCompany,
#contentPlantillas {
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 550px;
    background-color: var(--color-greenback);
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    display: none;
    flex-direction: column;
    padding: 25px 35px;
}

#actuaizarCompañia,
#crearCompañia,
#createUsuario,
#updateUsuario,
#contentImagenes,
#contentImagenesUpdate,
#CrearPlantillas,
#EliminarPlatillas,
#contentImagenesDelete,
#contentCompañiaDelete,
#contentUsuarioDelete {
    display: none;
}

#botonesEleccion {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
}

.contentHoraGet {
    width: 100%;
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
}

.formConfiguracion>div>select>option {
    color: var(--color-green);
}


@media (max-width: 1366px) {

    .contentCardConfiguracionOptions {
        width: 27%;
        padding: 22px;
        font-size: 16px;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        color: var(--color-font);
            height: 155px
    }
}


@media (max-width: 1220px) {

    .contentCardConfiguracionOptions {
        width: 27%;
    }
}