.volver-reportes {
    cursor: pointer;
}

.tableContainer-reportes {
    overflow: auto;
    margin: 15px;
    max-height: 400px;
    width: 97.5%;
    border-radius: 10px;
    background-color: white;
    color: black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.tableContainer-reportes::-webkit-scrollbar {
    -webkit-appearance: none;
}

.tableContainer-reportes::-webkit-scrollbar:vertical {
    width: 10px;
}

.tableContainer-reportes::-webkit-scrollbar-button:increment,
.tableContainer-reportes::-webkit-scrollbar-button {
    display: none;
}

.tableContainer-reportes::-webkit-scrollbar:horizontal {
    height: 10px;
}

.tableContainer-reportes::-webkit-scrollbar-thumb {
    background-color: var(--color-green);
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.tableContainer-reportes::-webkit-scrollbar-track {
    border-radius: 10px;
}

.Conten-datos-filtrados {
    width: 100%;
    margin-top: 20px;
    background-color: var(--color-green);
    border-radius: 10px;
    height: 50px;
}

.text-content-reportes {
    padding: 15px;
    display: flex;
    justify-content: space-evenly;
}

.filtrosReportes {
    display: flex;
    justify-content: center;
    margin-top: -10px;  
}

.selectDatosPrincipalesDataReportes {
    background-color: white;
    width: 160px;
    height: 50px;
    color: var(--color-green);
    border-radius: 10px;
    margin: 10px;
    font-size: 15px;
    text-align: center;
}
.selectDatosPrincipalesDataReportes::placeholder {
    color: var(--color-green);
}