.mainContenInicio_ {
    height: 540px;
    width: 90%;
    background-color: var(--color-greenback);
    position: absolute;
    transform: translate(-50%, -45%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 20px 35px;
    color: var(--color-button);
}

.navGeneral {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.selectDatosPrincipalesData {
    background-color: white;
    width: 340px;
    height: 50px;
    color: var(--color-green);
    border-radius: 10px;
    font-size: 15px;
    text-align: center;
}

#tamaño-imagen-advertencia {
    width: 20%;
    padding: 30px;
}

.content-advertencia {
    background-color: var(--color-yellow);
    padding: 20px;
    border-radius: 10px;
    border-radius: 10px;
}

.content-advertencia>p>a {
    text-decoration: none;
    color: var(--color-button);
}

/*-------------------------- tabla ----------------------------*/
.datos-container {
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 550px;
    background-color: var(--color-greenback);
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    /* display: fl; */
    flex-direction: column;
    padding: 25px 35px;
}

.backFormsRegister {
    /* margin-top: 330px; */
    transform: translate(0%, 600%);
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

/* button {
    margin-top: 29px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
} */

#botonesEleccionData {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

#crearRegistro,
#actuaizarRegistro,
#DeleteRegistro {
    display: none;
}

.navFilters {
    display: flex;
    width: 40%;
    justify-content: flex-end;
}

.AddData {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    height: 50px;
    margin-right: 10px;
    color: var(--color-green);
    border-radius: 10px;
    font-size: 15px;
    text-align: center;
}

.tableContainer {
    overflow: auto;
    max-height: 400px;
    position: absolute;
    transform: translate(3%, 5%);
    width: 90%;
    border-radius: 10px;
    background-color: white;
    color: black;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
}

td,
tr {
    padding: 20px;
}

.tableAll>thead>tr {
    padding: 20px;
}

.tableContainer::-webkit-scrollbar {
    -webkit-appearance: none;
}

.tableContainer::-webkit-scrollbar:vertical {
    width: 10px;
}

.tableContainer::-webkit-scrollbar-button:increment,
.tableContainer::-webkit-scrollbar-button {
    display: none;
}

.tableContainer::-webkit-scrollbar:horizontal {
    height: 10px;
}

.tableContainer::-webkit-scrollbar-thumb {
    background-color: var(--color-green);
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.tableContainer::-webkit-scrollbar-track {
    border-radius: 10px;
}


@media (max-width: 1366px) {
    .mainContenInicio_ {
        height: 460px;
        width: 95%;
    }

    .tableContainer {
        height: 330px;
    }
}

@media (max-width: 1220px) {
    .mainContenInicio_ {
        height: 460px;
        width: 95%;
    }

    .tableContainer {
        height: 320px;
    }
}