* {
  user-select: none;
  margin: 0;
}

:root {
  --color-green: #00a878;
  --color-red: #fe5e41;
  --color-button: #fdffff;
  --color-font: #1fcd8c;
  --color-black: #000;
  --color-verification: #1fcd8c;
  --color-null: rgb(75, 75, 75);
  --color-yellow: #ffa700;
  --color-borders: #345452;
  --color-greenback: #243136;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('../src//ImagenesInterfaz/IMG_E3530.svg') no-repeat center center fixed;
  background-size: cover;
}

/*-------------------------- Contenido del fondo borroso --------------------------*/

#all {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9;
  backdrop-filter: blur(5px);
}


/*--------------------------mensajes de login error--------------------------*/

#Text_conectnion {
  display: none;
  margin: 10px;
  font-size: 15px;
  padding: 5px;
  text-align: center;
  background-color: rgba(255, 140, 140, 0.508);
  border: solid 2px red;
  border-radius: 5px;
  transition: all 300ms ease-in-out;
  justify-content: center;
}

.Content_Swal_All {
  overflow: hidden;
  z-index: 9;
  backdrop-filter: blur(5px);
}

/*--------------------------textos--------------------------*/

#text-no-funtion {
  color: rgba(199, 199, 199, 0.779);
}

h1 {
  margin: 5px;
}

.textInicio {
  position: absolute;
  font-size: 20px;
  width: 200px;
  padding: 6px;
  text-align: center;
  transform: translate(0%, 220%);
}

.titleMainInicio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: -210px; */
}

.selectUser {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}

.selectUser>option {
  color: #00a878;
}

/*------------------------ alerta de info ---------------------------*/

.Content_Swall_error {
  /* margin-top: .1rem; */
  border: solid 2px rgb(255, 0, 0) !important;
  border-radius: 2rem !important;
  height: 3.5rem;
  display: flex !important;
  justify-content: space-around !important;
  background: white;
  color: #00a878;
  align-items: center;
  box-shadow: none !important;
  z-index: 999 !important;
}

.Content_Swall_succes_popap {
  /* margin-top: .1rem; */
  border: solid 2px rgb(128, 255, 0) !important;
  border-radius: 2rem !important;
  height: 3.5rem;
  display: flex !important;
  justify-content: space-around !important;
  background: white;
  color: #00a878;
  align-items: center;
  box-shadow: none !important;
  z-index: 999 !important;
}

.Content_Swall_succes_programate_popap {
  /* margin-top: .1rem; */
  border: solid 2px rgb(128, 255, 0) !important;
  border-radius: 2rem !important;
  height: 3.5rem;
  width: 350px !important;
  display: flex !important;
  justify-content: space-around !important;
  background: white;
  color: #00a878;
  align-items: center;
  box-shadow: none !important;
  z-index: 999 !important;
}

.container-swall {
  width: 90% !important;
}

.Content_Swall_info {
  border: solid 2px rgb(0, 229, 255) !important;
  border-radius: 1rem !important;
  height: 3.5rem;
  width: 100%;
  display: flex !important;
  justify-content: space-around !important;
  background: white;
  color: #414040;
  align-items: center;
  box-shadow: none !important;
  z-index: 999 !important;
}

/*------------------------ tablas ---------------------------*/

#Tabla_Datos {
  width: 100%;
  height: 500px;
  background-color: var(--color-green);
  border-radius: 10px;
  text-align: center;
  overflow: auto;
  display: none;
  justify-content: center;
}

#Tabla_Datos {
  padding: 20px 0px 0px 50px;
  color: #f1f2f3;
}

#Tabla_Datos::-webkit-scrollbar {
  -webkit-appearance: none;
}

#Tabla_Datos::-webkit-scrollbar:vertical {
  width: 10px;
}

#Tabla_Datos::-webkit-scrollbar-button:increment,
#Tabla_Datos::-webkit-scrollbar-button {
  display: none;
}

#Tabla_Datos::-webkit-scrollbar:horizontal {
  height: 10px;
}

#Tabla_Datos::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#Tabla_Datos::-webkit-scrollbar-track {
  border-radius: 10px;
}